(function () {
  "use strict";

  function heroSlider() {
    if (document.querySelector('.main-slider')) {
      const swiper = new Swiper('.main-slider .swiper', {
        loop: true,
        pagination: {
          el: ".main-slider-pagination",
          clickable: true
        },
        navigation: {
          nextEl: '.main-slider .slider-button-next',
          prevEl: '.main-slider .slider-button-prev',
        },
      })
    }
  }

  // Uluslararası Telefon Input
  function telefonInput() {
    // Slider Altı Form
    if (document.querySelector('#telInput')) {
      var input = document.querySelector("#telInput");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
    // Modal Form Tel
    if (document.querySelector('#modalTel')) {
      var input = document.querySelector("#modalTel");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
  }

  // Ne Dediler Slider
  function testimonialSlider() {
    if (document.querySelector('.testimonial-slider')) {
      const slider = new Swiper('.testimonial-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        pagination: {
          el: ".testimonial-slider .swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Blog Slider
  function blogSlider() {
    if (document.querySelector('.blog-slider')) {
      const slider = new Swiper('.blog-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".blog-slider .swiper-pagination",
          clickable: true
        },

        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Diğer Eğitimler Slider

  function digerEgitimlerSlider() {
    if (document.querySelector('.diger-egitimler-slider')) {
      const slider = new Swiper('.diger-egitimler-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".diger-egitimler-slider .swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        }
      })
    }
  }


  // Scroll top Button

  function scrollTop() {
    const btnScrollTop = document.getElementById('scrollTop')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 1000) {
        btnScrollTop.classList.remove('hide-btn');
      } else {
        btnScrollTop.classList.add('hide-btn');
      }
    })

    btnScrollTop.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    })
  }

  function initAcc(elem, option) {
    document.addEventListener('click', function (e) {
      if (!e.target.matches(elem + ' .a-btn')) return;
      else {
        if (!e.target.parentElement.classList.contains('active')) {
          if (option == true) {
            var elementList = document.querySelectorAll(elem + ' .a-container');
            Array.prototype.forEach.call(elementList, function (e) {
              e.classList.remove('active');
            });
          }
          e.target.parentElement.classList.add('active');
        } else {
          e.target.parentElement.classList.remove('active');
        }
      }
    });
  }


  function filtrele() {
    if (document.querySelector('.filtre-mobil')) {
      const filtrePanel = document.querySelector('.filtre-mobil')
      const btnFiltrePanelOpen = document.querySelector('.btn-filtre')
      //const btnFiltrePanelClose = document.querySelectorAll('[data-filtre-close]')

      btnFiltrePanelOpen.addEventListener('click', openFiltrePanel)
      //btnFiltrePanelClose.addEventListener('click', closeFiltrePanel)

      function openFiltrePanel() {
        if (!filtrePanel.classList.contains('open')) {
          const closeButton = document.createElement('button')
          closeButton.innerHTML = '&times;'
          closeButton.classList.add('btn-filtre-close')
          closeButton.addEventListener('click', closeFiltrePanel)
          filtrePanel.appendChild(closeButton)
          filtrePanel.classList.add('open')
        }
      }
      function closeFiltrePanel() {
        if (filtrePanel.classList.contains('open')) {
          document.querySelector('.btn-filtre-close').remove()
          filtrePanel.classList.remove('open')
        }
      }
    }
  }

  function btnTogglePassView() {
    if (document.getElementById('btnTogglePassView')) {
      const button = document.getElementById('btnTogglePassView')
      const input = button.previousElementSibling

      button.addEventListener('click', togglePassView)

      function togglePassView() {
        if (input.type === 'password') {
          input.type = 'text'
        } else {
          input.type = 'password'
        }
      }
    }
  }


  if (document.querySelector('.kredi-kart-form')) {
    let ccNumberInput = document.querySelector('.cc-number-input'),
      ccNumberPattern = /^\d{0,16}$/g,
      ccNumberSeparator = " ",
      ccNumberInputOldValue,
      ccNumberInputOldCursor,

      ccExpiryInput = document.querySelector('.cc-expiry-input'),
      ccExpiryPattern = /^\d{0,4}$/g,
      ccExpirySeparator = "/",
      ccExpiryInputOldValue,
      ccExpiryInputOldCursor,

      ccCVCInput = document.querySelector('.cc-cvc-input'),
      ccCVCPattern = /^\d{0,3}$/g,

      mask = (value, limit, separator) => {
        var output = [];
        for (let i = 0; i < value.length; i++) {
          if (i !== 0 && i % limit === 0) {
            output.push(separator);
          }

          output.push(value[i]);
        }

        return output.join("");
      },
      unmask = (value) => value.replace(/[^\d]/g, ''),
      checkSeparator = (position, interval) => Math.floor(position / (interval + 1)),
      ccNumberInputKeyDownHandler = (e) => {
        let el = e.target;
        ccNumberInputOldValue = el.value;
        ccNumberInputOldCursor = el.selectionEnd;
      },
      ccNumberInputInputHandler = (e) => {
        let el = e.target,
          newValue = unmask(el.value),
          newCursorPosition;

        if (newValue.match(ccNumberPattern)) {
          newValue = mask(newValue, 4, ccNumberSeparator);

          newCursorPosition =
            ccNumberInputOldCursor - checkSeparator(ccNumberInputOldCursor, 4) +
            checkSeparator(ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length), 4) +
            (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

          el.value = (newValue !== "") ? newValue : "";
        } else {
          el.value = ccNumberInputOldValue;
          newCursorPosition = ccNumberInputOldCursor;
        }

        el.setSelectionRange(newCursorPosition, newCursorPosition);

      },

      ccExpiryInputKeyDownHandler = (e) => {
        let el = e.target;
        ccExpiryInputOldValue = el.value;
        ccExpiryInputOldCursor = el.selectionEnd;
      },
      ccExpiryInputInputHandler = (e) => {
        let el = e.target,
          newValue = el.value;

        newValue = unmask(newValue);
        if (newValue.match(ccExpiryPattern)) {
          newValue = mask(newValue, 2, ccExpirySeparator);
          el.value = newValue;
        } else {
          el.value = ccExpiryInputOldValue;
        }
      };

    ccNumberInput.addEventListener('keydown', ccNumberInputKeyDownHandler);
    ccNumberInput.addEventListener('input', ccNumberInputInputHandler);

    ccExpiryInput.addEventListener('keydown', ccExpiryInputKeyDownHandler);
    ccExpiryInput.addEventListener('input', ccExpiryInputInputHandler);
  }

  function avatarUpload() {
    if (document.querySelector('#imgInp')) {
      imgInp.onchange = evt => {
        const [file] = imgInp.files
        if (file) {
          avatarPrev.src = URL.createObjectURL(file)
        }
      }
    }
  }

  heroSlider()
  // telefonInput()
  btnTogglePassView()
  testimonialSlider()
  digerEgitimlerSlider()
  blogSlider()
  initAcc('.acc.v1', true)
  filtrele()
  avatarUpload()
  // scrollTop()

})();